jQuery(document).ready(function($) {
  "use strict"

  $('.nav__toggle').click(function(){
    $(this).next('.nav-wrapper').slideToggle();
    $(this).toggleClass('active');
  });
  if ($(window).width() < 992){
    $('.header-middle .nav-wrapper ul li a').click(function(){
      $(this).next('ul').slideToggle();
      return false;
    });
  }

  //Navigation Submenu on Hover Active Class For Desktop View
  if ($(window).width() > 991){
    $('.header-middle .nav-wrapper ul li.has-submenu').hover(function () {
      $(this).addClass('active').siblings().removeClass('active');
      // $(this).addClass('active').siblings().children('.dropdown-item.has-sub').removeClass('active');
    });
    $('.header-middle .nav-wrapper ul li.has-submenu').mouseleave(function () {
      $(this).removeClass('active');
    });
  }

    //For open parent menu.
    $('.header-middle .nav-wrapper ul li a[href]').click(function() {
      var dropdown = $(this).next('.dropdown-menu');
      if (dropdown.length == 0 || $(dropdown).css('display') !== 'none') {
          if (this.href) {
              location.href = this.href;
          }
      }
  });

  //Contact form validation
  $('#contact-form').validate({
      rules: {
          username: {
              required: true, 
              maxlength:100,
              normalizer: function( value ) {
                return $.trim( value );
              }
          },
          email: {
              required: true,
              email: true
          },
          phone: {
              required: true,
              number: true,
              maxlength:25
          },
          address: {
              required: true, 
              normalizer: function( value ) {
                return $.trim( value );
              }
          },
      },
      errorPlacement: function(error,element) {
          return true;
      },
      highlight: function(element) {
          $(element).parent('div').addClass('error');
      },
      unhighlight: function(element) {
          $(element).parent('div').removeClass('error');
      },
      onfocusout: function(element)
      {         
          /*tmpval = $(element).val().trim();
          if (tmpval == '') {            
            $(element).siblings('.formlabel').removeClass('labelup');
          } else {
            $(element).siblings('.formlabel').addClass('labelup');
          }
          return true;*/
      }
  });
});

// tabbed content
$(".tab_content").hide();
$(".tab_content:first").show();

/* if in tab mode */
$("ul.tabs li").click(function() {
  
    $(".tab_content").hide();
    var activeTab = $(this).attr("rel"); 
    $("#"+activeTab).fadeIn();		
  
    $("ul.tabs li").removeClass("active");
    $(this).addClass("active");

  $(".tab_drawer_heading").removeClass("d_active");
  $(".tab_drawer_heading[rel^='"+activeTab+"']").addClass("d_active");
  
});
/* if in drawer mode */
$(".tab_drawer_heading").click(function() {
    
    $(".tab_content").hide();
    var d_activeTab = $(this).attr("rel"); 
    $("#"+d_activeTab).fadeIn();
  
  $(".tab_drawer_heading").removeClass("d_active");
    $(this).addClass("d_active");
  
  $("ul.tabs li").removeClass("active");
  $("ul.tabs li[rel^='"+d_activeTab+"']").addClass("active");
});

$('ul.tabs li').last().addClass("tab_last");

//Current Year
document.getElementById('copyrightyear').appendChild(document.createTextNode(new Date().getFullYear()))
	
/* Testimonial Slider */
$('.testimonial-slider').slick({
    infinite: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    autoplay: false, 
    speed: 1000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          adaptiveHeight: true                
        }
      }
    ]
});

document.addEventListener("DOMContentLoaded", function(){
  window.addEventListener('scroll', function() {
      if (window.scrollY > 50) {
        document.getElementById('navbar_top').classList.add('fixed-top');
        // add padding top to show content behind navbar
        navbar_height = document.querySelector('.header-middle').offsetHeight;
        document.body.style.paddingTop = navbar_height + 'px';
      } else {
        document.getElementById('navbar_top').classList.remove('fixed-top');
         // remove padding top from body
        document.body.style.paddingTop = '0';
      } 
  });
}); 

    